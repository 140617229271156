import React, { useState } from 'react';
import home from '../../../assets/home.png';
import { motion } from 'framer-motion';
import { textanimation } from './Animation/index';

type Props = {
  setOpenComponent: React.Dispatch<React.SetStateAction<number>>;
  setProduct: React.Dispatch<React.SetStateAction<string>>;
  product: string;
};

function Page11devCompoOne({ setOpenComponent, setProduct, product, }: Props) {


  return (
    <>
      <div className="pt-20">
        <motion.div
          variants={textanimation}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true }}
        >
          <p className="font-raleway mx-2 sm:mx-0 text-3xl  md:text-4xl lg:text-5xl xl:text-7xl text-center font-black" >
            <span className='text-brand'>Calculate,</span> Boost your credit score, and Get <span className='text-brand'>Approved</span>  online today!
          </p>
        </motion.div>
        <motion.div variants={textanimation} initial="offscreen" whileInView="onscreen" viewport={{ once: true }}>
          <p className="font-sans text-[#737373] font-bold text-sm sm:text-base md:text-xl lg:text-2xl xl:text-[32px] text-center mt-10 mx-2">
            Get your free credit score, property value and detailed mortgage plan - all at no cost.
          </p>
        </motion.div>
      </div>
      <div className="flex flex-col justify-center items-center mt-10 ">
        <div className="flex gap-10 sm:gap-0 flex-row w-full lg:w-[80%] flex-wrap justify-center items-center ">
          <div className="flex flex-col mt-2 sm:mt-0  md:basis-1/2 lg:basis-1/3 justify-center items-center md:justify-between  w-2/6 ">
            <div className="w-20 h-20 md:w-40 md:h-40">
              <img src={home} alt="" className="" />
            </div>
            <button
              id="page11_btn_HEL_dev"
              onClick={() => {
                setProduct('Home equity loan');
                setOpenComponent(2);
                document.getElementById('view')?.scrollIntoView();
              }}
              className="bg-brand select-none rounded-xl font-sans text-xs md:text-lg font-semibold text-white px-3 sm:px-2 pt-2 pb-2  mt-8 hover:bg-white hover:text-brand border-brand border-2"
            >
              Home equity loan
            </button>
          </div>
          <div className="flex flex-col mt-2 sm:mt-0  md:basis-1/2 lg:basis-1/3 justify-center items-center md:justify-between w-2/6 ">
            <div className="w-20 h-20 md:w-40 md:h-40">
              <img src={home} alt="" className="" />
            </div>
            <button
              id="page11_btn_HELOC_dev"
              onClick={() => {
                setProduct('Home equity line (HELOC)');
                setOpenComponent(2);
                document.getElementById('view')?.scrollIntoView();
              }}
              className="bg-brand select-none rounded-xl font-sans text-xs md:text-lg font-semibold text-white px-2 pt-2 pb-2  mt-8 hover:bg-white hover:text-brand border-brand border-2"
            >
              Home equity line (HELOC)
            </button>
          </div>
          <div className="flex flex-col mt-2 sm:mt-0 md:basis-1/2 lg:basis-1/3 justify-center items-center md:justify-between w-2/6 ">
            <div className="w-20 h-20 md:w-40 md:h-40">
              <img src={home} alt="" className="" />
            </div>
            <button
              id="page11_renew_dev"
              onClick={() => {
                setProduct('Renew my mortgage');
                setOpenComponent(2);
                document.getElementById('view')?.scrollIntoView();
              }}
              className="bg-brand select-none rounded-xl font-sans text-xs md:text-lg font-semibold text-white px-3 sm:px-2 pt-2 pb-2  mt-8 hover:bg-white hover:text-brand border-brand border-2"
            >
              Renew my mortgage
            </button>
          </div>
        </div>

      </div>
    </>
  );
}

export default Page11devCompoOne;
