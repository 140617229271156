import React from 'react'
import backButton from ".././../../../assets/backButton.svg";

interface BackButtonProps {
    setOpenComponent: (value: React.SetStateAction<number>) => void;
    id: number;
}

function BackButton({ setOpenComponent, id }: BackButtonProps) {
    return (
        <div className='ml-2 w-10' onClick={() => setOpenComponent(id)}>
            <img src={backButton} alt="" className="w-10  cursor-pointer" />
        </div>
    )
}

export default BackButton;