import React, { useEffect, useState } from 'react';
import SecPageCompoOne from './SixPageCompoOne';
import SecPageCompoTwo from './SixPageCompoTwo';
import Form from './Form/Form';
import Swal from 'sweetalert2';
import axios from 'axios';
import { Helmet } from 'react-helmet';

interface Props {
  reviewPage?: boolean;
}

function index({ reviewPage = false }: Props) {
  // Open targeted component hook
  const [openComponent, setOpenComponent] = useState<number>(1);

  // states to POST data
  const [goal, setGoal] = useState<string>('');
  const [product, setProduct] = useState<string>('');
  const [creditScore, setCreditScore] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [secondName, setSecondName] = useState<string>('');
  const [contact, setContact] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [place, setPlace] = useState<string>('');
  const [city, setCity] = useState<string>('');
  const [state, setState] = useState<string>('');
  const [zipCode, setZipCode] = useState<string>('');

  // useReducers**********

  const [homeWorthVal, setHomeWorthVal] = React.useReducer((state: string, newValue: string) => {
    const [formattedWholeValue, decimalValue = ''] = newValue.split('.');
    const significantValue = formattedWholeValue.replace(/,/g, '');
    const floatValue = parseFloat(significantValue + '.' + decimalValue.slice(0, 2));
    if (isNaN(floatValue) === false) {
      let n = new Intl.NumberFormat('en-EN', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }).format(floatValue);
      if (newValue.includes('.') && !n.includes('.')) {
        return n + '.';
      }
      return n;
    }
    return '';
  }, '');
  // ***********************
  const [mortgageBal, setMortgageBal] = React.useReducer((state: string, newValue: string) => {
    const [formattedWholeValue, decimalValue = ''] = newValue.split('.');
    const significantValue = formattedWholeValue.replace(/,/g, '');
    const floatValue = parseFloat(significantValue + '.' + decimalValue.slice(0, 2));
    if (isNaN(floatValue) === false) {
      let n = new Intl.NumberFormat('en-EN', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
      }).format(floatValue);
      if (newValue.includes('.') && !n.includes('.')) {
        return n + '.';
      }
      return n;
    }
    return '';
  }, '');
  // ***********************

  function phoneFormat(n: any) {
    let input = n.slice(2);
    var size = input.length;
    if (size > 0) {
      input = '(' + input;
    }
    if (size > 3) {
      input = input.slice(0, 4) + ') ' + input.slice(4, 11);
    }
    if (size > 6) {
      input = input.slice(0, 9) + '-' + input.slice(9);
    }
    return input;
    // console.log(input)
  }
  // *********


  const handleLandingPageData = async () => {
    let fullname = `${name} ${secondName}`;


    // --------------------------------------
    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'You’re all set!',
      text: 'Our team is already Working on your report.',
      customClass: {
        popup: 'page11_popup_dev'
      },
      inputAttributes: {
        id: 'page11_popup_dev'
      },
      allowOutsideClick: false,
      // showConfirmButton: false,
      // timer: 2500,

      showCancelButton: false,
      width: 400,
      confirmButtonColor: '#7059a7',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ok'
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.href = 'https://nuborrow.com/';
        return null;
      }
    });
    // --------------------------------------
    let formData = new FormData();
    formData.append('place', place);
    formData.append('imp_to_us', goal);
    formData.append('mortgage_balance_owing', `$${mortgageBal}`);
    formData.append('current_credit_score', creditScore);
    // formData.append('product_id', '1');
    formData.append('name', fullname);
    formData.append('email', email);
    formData.append('phone', phoneFormat(contact));
    formData.append('product', product);
    formData.append('city', city);
    formData.append('state', state);
    formData.append('zipCode', zipCode);
    formData.append('home_value', `$${homeWorthVal}`);
    formData.append('lead_source', '49'); //55 for live and 49 for dev on Page 11

    // // *********************API starts**********************
    console.log('line 195');
    // https://corsproxyapi.herokuapp.com/

    try {
      console.log('line 199');
      const data = await axios({
        method: 'post',
        url: 'https://dev.nu2morrow.com/crm/apis/lead/add_landingpage_dev',
        // url: 'https://nu2morrow.com/crm/apis/lead/add_landingpage',

        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      console.log('line 208');

      // console.log(formData);
    } catch (err) {
      console.log(err);
      console.log('line 213');
    }
    console.log('line 212');
    console.log(formData);

    // *************************API ends*************************

    // console.log(`$${home_value}`)
    // console.log(`$ ${cost}`)
    console.log(place);

    setProduct('');
    // setPropertyType('')
    // setCost('')
    setName('');
    setContact('');
    setName('');
    setSecondName('');
    setCreditScore('');
    setGoal('');
    setContact('');
    setEmail('');
    setPlace('');
    setCity('');
    setState('');
    setZipCode('');

  };

  console.log(openComponent);

  return (
    <div id="view" className="w-full container mx-auto px-5">
      <Helmet>
        <script>
          {`
            dataLayer.push({
              'event':'formsubmitted',
              'enhanced_conversion_data': {
                "email": ${email},
                "phone_number": ${contact}
              }
            })
          `}
        </script>
      </Helmet>
      {openComponent === 1 ? (
        <SecPageCompoOne
          setProduct={setProduct}
          product={product}
          setOpenComponent={setOpenComponent}
        />
      ) : openComponent === 2 ? (
        <SecPageCompoTwo
          goal={goal}
          creditScore={creditScore}
          setGoal={setGoal}
          setCreditScore={setCreditScore}
          setOpenComponent={setOpenComponent}
          setHomeWorthVal={setHomeWorthVal}
          homeWorthVal={homeWorthVal}
          mortgageBal={mortgageBal}
          setMortgageBal={setMortgageBal}
        />
      ) : (
        openComponent === 3 && (
          <Form
            setEmail={setEmail}
            email={email}
            setOpenComponent={setOpenComponent}
            handleLandingPageData={handleLandingPageData}
            setName={setName}
            name={name}
            setContact={setContact}
            contact={contact}
            setState={setState}
            setPlace={setPlace}
            setCity={setCity}
            setZipCode={setZipCode}
            place={place}
            backId={2}
            state={state}
            buttonId="page11_btn_submit_report_dev"
          />
        )
      )}
    </div>
  );
}

export default index;
