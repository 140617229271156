import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { textanimation } from '../../Animation/index';
import PhoneInput from 'react-phone-number-input/input';
import Autocomplete from 'react-google-autocomplete';
import axios from 'axios';
import backButton from '../../../../assets/backButton.svg';

type Props = {
  setOpenComponent: React.Dispatch<React.SetStateAction<number>>;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  setName: React.Dispatch<React.SetStateAction<string>>;
  name: string;
  setContact: React.Dispatch<React.SetStateAction<string>>;
  contact: string;
  setCity: React.Dispatch<React.SetStateAction<string>>;
  setPlace: React.Dispatch<React.SetStateAction<string>>;
  setZipCode: React.Dispatch<React.SetStateAction<string>>;
  setState: React.Dispatch<React.SetStateAction<string>>;
  place: string;
  handleLandingPageData: () => Promise<void>;
  backId: number;
  buttonId?: string;
  state?: string;
};

const SecPageCompoSix = ({
  setOpenComponent,
  email,
  setEmail,
  handleLandingPageData,
  setName,
  name,
  setContact,
  contact,
  setPlace,
  setCity,
  setZipCode,
  setState,
  place,
  backId,
  buttonId,
  state
}: Props) => {
  // const [email, setEmail] = useState("")
  const [fName, setFName] = useState('');
  const [errorFname, setErrorFname] = useState('');

  const [emailError, setEmailError] = useState('');
  const [validEmail, setValidEmail] = useState('');

  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const [address, setAddress] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  //******Button Enable/Disable******/
  const [disable, setDisable] = useState(false);
  //******Button Enable/Disable******/

  useEffect(() => {
    setFName(name);
    setPhone(contact);
    setAddress(place);
  }, []);


  const inputs = document.querySelectorAll("input");
  inputs.forEach((input) => {
    input.addEventListener("keypress", (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
        const currentTabIndex = input.tabIndex;
        const nextInput = document.querySelector(
          `input[tabIndex="${currentTabIndex + 1}"]`
        );
        if (nextInput) {
          (nextInput as HTMLInputElement).focus();
        }
      }
    });
  });

  const clickHandler = async () => {
    console.log('button pressed');
    if (!email || !fName || !address || !phone) {
      !fName && setErrorFname('Name is required');
      !address && setErrorMsg('Address is required');
      !email && setEmailError('Email is required');
      !phone && setPhoneError('Phone is required');
      return;
    } else if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email) === false) {
      return setValidEmail('Enter the valid email');
    }

    //  ***********************
    if (phone.length < 12) {
      setPhoneError('Please enter valid phone number');
    }
    const resp = await checkPhoneIsCanadianValidOrNot();
    //*****************
    if (resp === 200) {
      if (state) {
        if (state === 'ON') {
          handleLandingPageData();
          document.getElementById('view')?.scrollIntoView();
          setOpenComponent(9);
          return;
        }
        setErrorMsg('Enter Ontario Address');
      } else {
        handleLandingPageData();
        document.getElementById('view')?.scrollIntoView();
        setOpenComponent(9);
      }
    }
  };

  const checkPhoneIsCanadianValidOrNot = async () => {
    const formData = new FormData();
    formData.append('contact_no', contact);

    console.log('formData', formData);
    try {
      const { data } = await axios({
        method: 'post',
        url: 'https://nu2morrow.com/crm/apis/SecUser/contactvalidator',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' }
      });
      if (data?.status !== 200) {
        return alert('Invalid number');
      }
      return data?.status;
      // console.log(data?.data);
    } catch (err) {
      setPhoneError('Please enter valid phone number');
      //   const invalidError = err.message;
      //   console.log(err.message);
      // return Swal.fire({
      //   icon: 'error',
      //   title: 'Oops...',
      //   text: 'Number is not valid!'
      //   // footer: '<a href="">Why do I have this issue?</a>'
      // });
      //   setValidPhone(invalidError)
    }
  };

  const changeFunction = (e: any) => {
    setPhone(e);
    setContact(e);
    setPhoneError('');
  };

  const chkAddressFieldEmptyOrField = (e: any) => {
    setAddress('');
    setErrorMsg('');
  };

  const handleSelect = async (value: any) => {

    let address_country = '';
    let postal_code = '';
    let address_state = '';
    let address_city = '';
    let place = '';

    value?.address_components?.forEach((item: any) => {
      const countryFound = item.types.find((e: any) => e === 'country');
      const postalFound = item.types.find((e: any) => e === 'postal_code');
      const placeFound = item.types.find((e: any) => e === 'route');
      const stateFound = item.types.find((e: any) => e === 'administrative_area_level_1');
      const cityFound = item.types.find((e: any) => e === 'locality');

      if (countryFound) {
        address_country = item.short_name;
      }
      if (postalFound) {
        postal_code = item.long_name;
      }
      if (stateFound) {
        address_state = item.short_name;
      }
      if (cityFound) {
        address_city = item.long_name;
      }
      if (placeFound) {
        place = item.long_name;
      }
    });

    // ****************
    setState(address_state);
    setZipCode(postal_code);
    setCity(address_city);
    setPlace(`${place}, ${address_city}, ${address_state}, ${postal_code}`);
  };
  // *********onClick*********

  return (
    <div className={`mt-10 `}>
      <div
        onClick={() => {
          setOpenComponent(backId);
        }}
      >
        <img src={backButton} alt="" className="w-10 cursor-pointer" />
      </div>
      <div className="bg-mainCard">
        <div className="pt-20">
          <motion.div
            variants={textanimation}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true }}
          >
            <p className="font-raleway mx-2 sm:mx-0 text-3xl md:text-4xl lg:text-5xl xl:text-6xl text-center font-black">
              We can’t wait to <span className='text-brand'>get started</span> on your reports!
            </p>
          </motion.div>
          <motion.div
            variants={textanimation}
            initial="offscreen"
            whileInView="onscreen"
            viewport={{ once: true }}
          >
            <p className="font-sans font-bold text-sm sm:text-base md:text-xl  text-center text-black mt-10">
              We also hate SPAM, your data will be kept confidential.
            </p>
          </motion.div>
        </div>
        {/* name input */}
        <div className="flex flex-col justify-center items-center mt-10 gap-4 md:gap-8 ">
          <div className="w-full flex max-md:flex-col justify-center items-center gap-4 md:gap-8 lg:gap-12">
            <div className='w-full md:w-fit'>
              <div className="bg-white w-full md:w-fit rounded-full border-2 ">
                <input
                  type="text"
                  pattern="[a-zA-z]+"
                  value={name && name}
                  placeholder="Your Name"
                  tabIndex={1}
                  required
                  onChange={(e) => {
                    if (/^[a-zA-Z\s]*$/.test(e.target.value)) {
                      //   setEmailE(e.target.value)
                      setName(e.target.value);
                      setFName(e.target.value);
                      setErrorFname('');
                    }
                  }}
                  className="outline-none w-full rounded-full px-8 font-sans text-base pl-4 md:px-16 py-2"
                />
              </div>

              <span className="text-red-700 text-center sm:w-[65%] mx-auto  text-[10px] sm:text-[12px] font-semibold">
                {errorFname}
              </span>
            </div>
            {/* email input */}
            <div className='w-full md:w-fit'>
              <div className="bg-white w-full md:w-fit rounded-full border-2">
                <input
                  type="email"
                  tabIndex={2}
                  value={email && email}
                  placeholder="example@gmail.com"
                  required
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setEmailError('');
                    setValidEmail('');
                  }}
                  className="outline-none w-full rounded-full px-8 font-sans text-base pl-4 md:px-16 py-2"
                />
              </div>
              <span className="text-red-700 text-center sm:w-[65%] mx-auto text-[10px] sm:text-[12px] font-semibold">
                {emailError ? emailError : validEmail ? validEmail : null}
              </span>
            </div>
          </div>

          {/* phone input */}
          <div className="w-full flex max-md:flex-col justify-center items-center gap-4 md:gap-8 lg:gap-12">
            <div className='w-full md:w-fit'>
              <div className="bg-white w-full md:w-fit rounded-full border-2 ">
                <PhoneInput
                  className="outline-none w-full rounded-full px-8 font-sans text-base pl-4 md:px-16 py-2"
                  placeholder="Your Phone"
                  maxlength="14"
                  tabIndex={3}
                  country="US"
                  value={contact && contact}
                  onChange={changeFunction}
                />
              </div>
              <span className="text-red-700 text-center sm:w-[65%] mx-auto text-[10px] sm:text-[12px] font-semibold">
                {phoneError}
              </span>
            </div>
            {/* </div>

					{/* address input */}
            <div className='w-full md:w-fit'>
              <div className="bg-white rounded-full border-2 w-full md:w-fit">
                <Autocomplete
                  apiKey="AIzaSyDKhj1caiJVMeNgBAqbOjV97q0oMyAuRiQ"
                  tabIndex={4}
                  // style={{ width: '90%' }}
                  className="outline-none rounded-full font-sans text-base px-4 md:px-16 py-2 w-full"
                  // value={address}
                  onPlaceSelected={(place) => {
                    handleSelect(place);
                    setAddress(place?.formatted_address);
                  }}
                  placeholder="Your Home Address"
                  options={{
                    types: ['geocode', 'establishment'],
                    componentRestrictions: { country: 'ca' }
                  }}
                  // defaultValue={address}
                  defaultValue={place && place}
                  onChange={chkAddressFieldEmptyOrField}
                // onSelect={handleSelect}
                />
              </div>
              <span className="text-red-700 text-center sm:w-[65%] mx-auto  text-[10px] sm:text-[12px] font-semibold">
                {errorMsg}
              </span>
            </div>
          </div>

          {/* checkbox */}
          <div className="pt-4 w-fit">
            <input
              onClick={() => {
                disable === true ? setDisable(false) : setDisable(true);
              }}
              type="checkbox"
              id="id1"
              name="id1"
              value=""
              tabIndex={5}
              className="w-5 h-5 accent-brand cursor-pointer"
            />
            <label
              htmlFor="id1"
              className="font-sans font-base max-sm:text-sm  text-center mt-10 ml-4 text-[#737373]"
            >
              By clicking this button, you agree to and acknowledge Nuborrow's Terms of Use and <a href="https://nuborrow.com/privacy-policy/" className="">Privacy Policy.</a>
            </label>
          </div>

          {/* button */}
          <div className="mb-10">
            <button
              id={buttonId}
              tabIndex={6}
              // disabled
              onClick={() => {
                disable ? clickHandler() : null;
              }}
              // {!disable}
              //
              className={`px-8 py-3 sm:px-16 sm:py-4 mt-8 rounded-full font-sans text-lg font-bold text-white  border-2 
                ${disable
                  ? `bg-brand rounded-full font-sans text-lg font-bold text-white  hover:bg-white hover:text-brand border-brand `
                  : `bg-gray-300  cursor:arrow border-gray-300`}
              `}
            >
              Get your report
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecPageCompoSix;
